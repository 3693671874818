.border-carousel-main {
    border: 2px solid #1890ff; /* Subtle blue border for professionalism */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 8px; /* Rounded corners for modern look */
    padding: 20px; /* Adequate padding for inner content */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.border-carousel-main:hover {
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15); /* Slightly more prominent shadow on hover */
    border-color: #40a9ff; /* Brighter border color on hover */
}

.border-carousel-main:focus-within {
    border-color: #40a9ff; /* Highlight border when inputs are focused */
    box-shadow: 0px 4px 20px rgba(72, 144, 226, 0.2); /* Softer glow when interacting with inputs */
}
